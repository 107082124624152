<template>
  <h-card>
    <b-row class="d-block d-lg-none">
      <b-col class="d-flex align-items-center">
        <h4 class="mt-0">{{ $t('player.filters') }}</h4>
        <b-icon
          @click="collapsedFilters = !collapsedFilters"
          icon="chevron-up"
          class="ml-auto"
          :class="{ rotate: !collapsedFilters, out: collapsedFilters }"
          scale="1.5"
        ></b-icon>
      </b-col>
    </b-row>
    <b-row class="filters-row" :class="{ collapsed: collapsedFilters }">
      <b-col class="flex-grow-1" md="12" lg>
        <b-row>
          <b-col lg="6" xl="3" class="pr-lg-0">
            <h-input
              v-model="name"
              :value-prop="name"
              size="lg"
              :placeholder="$t('player.search')"
              left-icon="search"
              @keyup.enter="filter"
            ></h-input>
          </b-col>
          <b-col lg="6" xl="3" class="mt-2 mt-lg-0 pr-lg-0">
            <h-multi-select
              v-model="tags"
              :placeholder="$t('player.playerTagLabel')"
              left-icon="tags"
              :options="tagsOption"
              :selectedTags="selectedTags"
            ></h-multi-select>
          </b-col>
          <b-col lg="6" xl="3" class="mt-2 mt-xl-0 pr-lg-0">
            <h-select
              v-model="playerType"
              :placeholder="$t('player.playerTypeLabel')"
              left-icon="collection-play"
              :options="playerTypeOptions"
            ></h-select>
          </b-col>
          <b-col lg="6" xl="3" class="mt-2 mt-xl-0 pr-lg-0">
            <h-select
              v-model="playerState"
              :placeholder="$t('player.playerStateLabel')"
              left-icon="hdd-network"
              :options="playerStateOptions"
            ></h-select>
          </b-col>
        </b-row>
      </b-col>
      <div class="flex-shrink-1 ml-0 ml-lg-3">
        <h-button
          @click="filter"
          :disabled="!playersLoaded"
          blue
          outline
          class="ml-3 mt-3 mt-lg-0"
        >
          <b-icon icon="filter" class="mr-2"></b-icon>{{ $t('player.filter') }}
        </h-button>
      </div>
    </b-row>
  </h-card>
</template>

<script>
import { mapState } from 'vuex'
export default {
  created() {
    this.$store.dispatch('player/_getTagsFilters').then(() => {
      this.fetchFilters()
    })
  },
  data() {
    return {
      collapsedFilters: true,
      name: '',
      tags: [],
      selectedTags: [],
      playerType: 0,
      playerState: 0,
      //Options
      playerTypeOptions: [
        { label: 'Windows App', value: 2 },
        // { label: 'Android App', value: 0 },
        // { label: 'iOS App', value: 0 },
        // { label: 'Sonos', value: 0 },
        // { label: 'Streaming', value: 0 },
        { label: 'Hardware player', value: 1 },
        // { label: 'Digital signage', value: 0 },
      ],
      playerStateOptions: [
        { label: this.$t('player.status.connected'), value: 1 },
        { label: `${this.$t('player.status.disconnected')} (+24h)`, value: 2 },
        { label: `${this.$t('player.status.disconnected')} (+72h)`, value: 3 },
        { label: this.$t('player.status.neverConnected'), value: 4 },
      ],
    }
  },
  methods: {
    fetchFilters() {
      //Retrieve Name
      this.name = this.playersFilters.SiteName
      //Retrieve Tags
      if (this.playersFilters.SiteTagIds) {
        for (const tagOpt of this.tagsOption) {
          for (const tag of tagOpt.tags) {
            if (this.playersFilters.SiteTagIds.includes(tag.id)) {
              this.selectedTags.push(tag)
            }
          }
        }
      }
      //Retrieve PlayerType
      const playerTypeOpt = this.playerTypeOptions.filter(
        (elem) => elem.value === this.playersFilters.PlayerType
      )
      this.playerType = playerTypeOpt[0]
      //Retrieve PlayerState
      const playerStateOpt = this.playerStateOptions.filter(
        (elem) => elem.value === this.playersFilters.PlayerState
      )
      this.playerState = playerStateOpt[0]
    },
    filter() {
      let playerFilter = {
        SiteName: this.name,
        SiteTagIds: this.tags,
        OfferType: 1, //only radio players
        PlayerType: this.playerType ? this.playerType.value : 0,
        PlayerState: this.playerState ? this.playerState.value : 0,
      }
      this.$store.dispatch('player/_setplayersFilters', playerFilter)
      this.$emit('filter')
    },
  },
  computed: {
    ...mapState({
      playersFilters: (state) => state.player.playersFilters,
      playersLoaded: (state) => state.player.playersLoaded,
      tagsOption: (state) => state.player.tagsFilter,
    }),
  },
}
</script>

<style lang="scss" scoped>
.filters-row {
  padding-right: 15px;
  .row {
    padding-right: 0;
  }
}

@media screen and (max-width: 991.5px) {
  .filters-row {
    padding-right: 0;
    .row {
      padding-right: initial;
    }
  }
}

.rotate {
  transition: 0.1s ease-out;
}

.out {
  transition: 0.1s ease-out;
  transform: rotate(180deg);
}
</style>
