var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item-container"},[(_vm.player)?_c('b-row',{staticClass:"player-item d-flex align-items-center mb-3"},[_c('b-col',{staticClass:"w-50-mobile d-flex"},[_c('PlayersItemStatus',{staticClass:"d-block d-md-none",attrs:{"heartbeat":_vm.player.heartbeat,"connected":_vm.player.connected}}),_c('div',{staticClass:"player-ico mr-2 d-none d-md-block"},[_c('img',{attrs:{"src":require(("@/assets/svg/players/" + (_vm.playerTypeImg(
              _vm.player.lastIdApplication
            )) + ".svg")),"alt":"player type"}})]),_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.player.name)+" ")])],1),_c('b-col',{staticClass:"d-none d-md-flex align-items-center"},[_c('PlayersItemStatus',{attrs:{"withLabel":"","heartbeat":_vm.player.heartbeat,"connected":_vm.player.connected}})],1),_c('b-col',{staticClass:"d-none d-lg-block",attrs:{"cols":"3"}},[(
          _vm.player.extended &&
            _vm.player.extended.currentTrack &&
            _vm.player.extended.currentTrack.type
        )?_c('b-icon',{attrs:{"icon":_vm.returnIcoCurrentTrack(_vm.player.extended.currentTrack.type)}}):_vm._e(),_vm._v(" "+_vm._s(_vm.player.extended && _vm.player.extended.currentTrack && _vm.player.extended.currentTrack.title ? _vm.player.extended.currentTrack.title : '-')+" ")],1),_c('b-col',{staticClass:"w-50-mobile d-flex justify-content-end justify-content-md-end flex-column flex-md-row"},[_c('router-link',{attrs:{"to":("/players/" + (_vm.player.idPlayer))}},[_c('h-button',{staticClass:"no-white-space",attrs:{"flat":"","grey":""}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"gear-wide-connected"}}),_vm._v(_vm._s(_vm.$t('player.control'))+" ")],1)],1),_c('router-link',{attrs:{"to":{
          path: ("/players/" + (_vm.player.idPlayer) + "/playlist"),
          query: { player: _vm.player.name },
        }}},[_c('h-button',{staticClass:"no-white-space",attrs:{"transp":"","orange":""}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"music-note-list"}}),_vm._v(" "+_vm._s(_vm.$t('player.seePlaylist'))+" ")],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }