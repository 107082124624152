<template>
  <div class="skeleton-player">
    <div class="d-none d-xxl-block">
      <vue-content-loading :width="1130" :height="45">
        <rect x="2" y="9" rx="1" ry="1" width="180" height="20" />
        <rect x="275" y="9" rx="4" ry="4" width="185" height="20" />
        <rect x="545" y="9" rx="4" ry="4" width="230" height="20" />
        <rect x="840" y="9" rx="4" ry="4" width="100" height="20" />
        <rect x="955" y="9" rx="4" ry="4" width="180" height="20" />
      </vue-content-loading>
    </div>
    <div class="d-none d-xl-block d-xxl-none">
      <vue-content-loading :width="1070" :height="45">
        <rect x="2" y="9" rx="1" ry="1" width="160" height="20" />
        <rect x="255" y="9" rx="4" ry="4" width="185" height="20" />
        <rect x="510" y="9" rx="4" ry="4" width="215" height="20" />
        <rect x="780" y="9" rx="4" ry="4" width="100" height="20" />
        <rect x="900" y="9" rx="4" ry="4" width="180" height="20" />
      </vue-content-loading>
    </div>
    <div class="d-none d-lg-block d-xl-none d-xxl-none">
      <vue-content-loading :width="1070" :height="45">
        <rect x="2" y="15" rx="1" ry="1" width="160" height="20" />
        <rect x="235" y="15" rx="4" ry="4" width="185" height="20" />
        <rect x="470" y="15" rx="4" ry="4" width="170" height="20" />
        <rect x="720" y="15" rx="4" ry="4" width="110" height="20" />
        <rect x="860" y="15" rx="4" ry="4" width="180" height="20" />
      </vue-content-loading>
    </div>
    <div class="d-none d-md-block d-lg-none d-xl-none d-xxl-none">
      <vue-content-loading :width="690" :height="45">
        <rect x="2" y="16" rx="1" ry="1" width="140" height="20" />
        <rect x="180" y="16" rx="4" ry="4" width="120" height="20" />
        <rect x="380" y="16" rx="4" ry="4" width="110" height="20" />
        <rect x="515" y="16" rx="4" ry="4" width="180" height="20" />
      </vue-content-loading>
    </div>
    <div
      class="d-flex d-md-none d-lg-none d-xl-none d-xxl-none align-items-center justify-content-center"
    >
      <b-spinner class="grey mt-4"></b-spinner>
    </div>
  </div>
</template>

<script>
import VueContentLoading from 'vue-content-loading'
export default {
  components: {
    VueContentLoading,
  },
}
</script>

<style lang="scss" scoped>
.skeleton-player {
  height: 60px;
}
.grey {
  color: #e1e1e1;
}
@media screen and (min-width: 768px) {
  .skeleton-message {
    height: 78px;
  }
}
</style>
