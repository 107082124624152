<template>
  <div class="item-container">
    <b-row class="player-item d-flex align-items-center mb-3" v-if="player">
      <b-col class="w-50-mobile d-flex">
        <PlayersItemStatus
          class="d-block d-md-none"
          :heartbeat="player.heartbeat"
          :connected="player.connected"
        />
        <div class="player-ico mr-2 d-none d-md-block">
          <img
            :src="
              require(`@/assets/svg/players/${playerTypeImg(
                player.lastIdApplication
              )}.svg`)
            "
            alt="player type"
          />
        </div>
        <span class="name">
          {{ player.name }}
        </span>
      </b-col>
      <b-col class="d-none d-md-flex align-items-center">
        <PlayersItemStatus
          withLabel
          :heartbeat="player.heartbeat"
          :connected="player.connected"
        />
      </b-col>
      <b-col class="d-none d-lg-block" cols="3">
        <b-icon
          v-if="
            player.extended &&
              player.extended.currentTrack &&
              player.extended.currentTrack.type
          "
          :icon="returnIcoCurrentTrack(player.extended.currentTrack.type)"
        ></b-icon>
        {{
          player.extended &&
          player.extended.currentTrack &&
          player.extended.currentTrack.title
            ? player.extended.currentTrack.title
            : '-'
        }}
      </b-col>
      <b-col
        class="w-50-mobile d-flex justify-content-end justify-content-md-end flex-column flex-md-row"
      >
        <router-link :to="`/players/${player.idPlayer}`">
          <h-button class="no-white-space" flat grey>
            <b-icon icon="gear-wide-connected" class="mr-2"></b-icon
            >{{ $t('player.control') }}
          </h-button>
        </router-link>
        <router-link
          :to="{
            path: `/players/${player.idPlayer}/playlist`,
            query: { player: player.name },
          }"
        >
          <h-button transp orange class="no-white-space">
            <b-icon icon="music-note-list" class="mr-2"> </b-icon>
            {{ $t('player.seePlaylist') }}
          </h-button>
        </router-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PlayersItemStatus from '@/components/player/PlayersItemStatus'
export default {
  components: { PlayersItemStatus },
  props: {
    player: {
      Type: Object,
      required: true,
    },
  },
  methods: {
    playerTypeImg(type) {
      switch (type) {
        case 1:
          return 'hardware'
        case 2:
          return 'hardware'
        case 3:
          return 'android'
        case 4:
          return 'ios'
        case 5:
          return 'sonos'
        case 6:
          return 'hardware'
        case 7:
          return 'webplayer'
        case 8:
          return 'webplayer'
        case 9:
          return 'Manager Linux'
        case 11:
          return 'webplayer'
        case 12:
          return 'unknown'
        case 15:
          return 'windows'
        default:
          return 'unknown'
      }
    },
    returnIcoCurrentTrack(type) {
      switch (type) {
        case 1: //song
          return 'music-note-beamed'
        case 2: //message
          return 'mic-fill'
        case 3:
          return 'file-earmark-play-fill'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.item-container:last-child .player-item {
  margin-bottom: 0 !important;
}
.player-item {
  .player-ico {
    text-align: center;
    width: 26px;
    img {
      width: 100%;
      max-height: 28px;
    }
  }
  .name {
    font-weight: 600;
    text-transform: lowercase;
    &::first-letter {
      text-transform: uppercase;
    }
  }
}
</style>
